import React, { useState } from "react"
import { ToastContainer, toast } from "react-toastify"

import Layout from "../components/layout/index"
import telIcon from "../assets/telBlack.svg"
import mailIcon from "../assets/mailBlack.svg"
import pinIcon from "../assets/pinBlack.svg"
import Button from "../components/buttons/overview_button"
import profileIcon from "../assets/profile.svg"
import mailGrayIcon from "../assets/mailGray.svg"
import Seo from "../components/seo"

const Contact = () => {
  const [fullname, setFullname] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSendMessage = async e => {
    setLoading(true)
    e.preventDefault()

    const { data, status } = await fetch(
      "https://staging.angazaelimu.com/api/contactUs",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ name: fullname, email, message }),
      }
    )
      .then(
        async res =>
          res.ok
            ? { data: await res.json(), status: res.status }
            : { data: undefined, status: 500 } //if server fails
      )
      .catch(e => console.log(e))
      .finally(() => setLoading(false))

    if (status === 500 || !data)
      return toast.error("Message not sent, please retry.")

    toast.success(data.message)

    //reset form
    setEmail("")
    setFullname("")
    setMessage("")
  }

  return (
    <Layout>
      <Seo title="Contact" />
      <div className="flex flex-col md:flex-row justify-center gap-12 md:gap-3 px-3 md:py-20 md:w-11/12 lg:w-9/12 mx-auto pb-24">
        <div className="flex flex-1 flex-col gap-7 mt-3 md:mt-8">
          <div className="">
            <h3 className="text-[2.5rem] font-bold">Get in touch.</h3>
            <p className="w-3/4 md:text-lg leading-snug">
              Our awesome team can’t wait to hear from you.
            </p>
          </div>

          <div className="flex flex-col gap-3">
            <a
              className="flex gap-2 items-center cursor-pointer hover:underline w-fit transition-all duration-300 ease-linear"
              href="tel:+254 703 593005"
            >
              <img src={telIcon} className="" alt="" />
              <p>+254 703 593005</p>
            </a>

            <a
              className="flex gap-2 items-center cursor-pointer hover:underline w-fit transition-all duration-300 ease-linear"
              href="mailto:info@angazaelimu.com"
            >
              <img src={mailIcon} className="" alt="" />
              <p>info@angazaelimu.com</p>
            </a>

            <a
              className="flex gap-2 cursor-pointer hover:underline w-fit transition-all duration-300 ease-linear"
              href="mailto:info@angazaelimu.com"
            >
              <img src={pinIcon} className="self-start" alt="" />
              <p>
                @iBizAfrica Keri Rd, <br />
                Strathmore Uni{" "}
              </p>
            </a>
          </div>
        </div>

        <div className="rounded-xl md:rounded-3xl shadow-xl drop-shadow-xl justify-self-start bg-light md:w-1/2 flex-1">
          <form
            onSubmit={handleSendMessage}
            className="px-4 md:px-6 lg:px-10 py-6 flex flex-col gap-3"
          >
            <div className="flex flex-col">
              <label
                htmlFor="fullname"
                className="text-sm mb-1.5 text-neutral-main"
              >
                Full names
              </label>

              <div className="flex items-center pl-2 rounded-lg border border-neutral-main/60 bg-[#fff] focus-within:border-primary-main transition-all duration-300 ease-linear">
                <img src={profileIcon} alt="" />
                <input
                  type="text"
                  name=""
                  id="fullname"
                  className="outline-none bg-transparent px-2 py-1.5 placeholder:text-sm w-full "
                  placeholder="Names"
                  value={fullname}
                  onChange={e => setFullname(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="text-sm mb-1.5 text-neutral-main"
              >
                Email address
              </label>

              <div className="flex items-center pl-2 rounded-lg border border-neutral-main/60 bg-[#fff] focus-within:border-primary-main transition-all duration-300 ease-linear">
                <img src={mailGrayIcon} className="select-none" alt="" />
                <input
                  type="email"
                  name=""
                  id="email"
                  className="outline-none bg-transparent px-2 py-1.5 placeholder:text-sm w-full"
                  placeholder="name@example.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="message"
                className="text-sm mb-1.5 text-neutral-main"
              >
                Message
              </label>
              <textarea
                type="email"
                name=""
                id="message"
                className="outline-none bg-[#fff] rounded-lg border border-neutral-main/60 px-2 py-1.5 placeholder:text-sm resize-none w-full focus:border-primary-main transition-all duration-300 ease-linear"
                rows={8}
                placeholder="Type message here"
                value={message}
                onChange={e => setMessage(e.target.value)}
                required
              />
            </div>

            <Button
              text="Send message"
              type="submit"
              className="mt-5"
              loading={loading}
            />
          </form>
        </div>
      </div>

      <ToastContainer theme="colored" hideProgressBar closeButton={false} />
    </Layout>
  )
}

export default Contact
